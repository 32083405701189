<template>
  <v-app style="background-color: #eee; background-image: url(/static/top.jpg); background-size: 100% auto">
    <v-main>
      <router-view :key="$route.path"></router-view>
      <!-- App Footer -->
      <v-footer height="auto" class="pa-3 app--footer">
        <v-row justify="center" no-gutters>
          <span v-if="$route.path === '/policy/'">{{ $t('menu.policy_index_short') }}</span>
          <router-link v-else :to="{ name: 'PolicyIndex' }">{{ $t('menu.policy_index_short') }}</router-link>
          <span class="px-2">|</span>
          <span v-if="$route.path === '/inquiry/'">{{ $t('menu.inquiry') }}</span>
          <router-link v-else :to="{ name: 'InquiryIndex' }">{{ $t('menu.inquiry') }}</router-link>
          <v-col class="text-center" cols="12">
            &copy; かみしほろルーラルOS推進協議会 {{ new Date().getFullYear() }}
          </v-col>
        </v-row>
      </v-footer>
    </v-main>
  </v-app>
</template>

<script>
export default {
  data: () => ({}),
  methods: {},
}
</script>
<style lang="sass" scoped>
.layout-auth
  height: 50%
  width: 100%
  position: absolute
  top: 0
  left: 0
  content: ""
  z-index: 0
</style>
