<template>
  <div>
    <app-toolbar class="admin_toolbar" extended />
    <v-main>
      <!-- Page Wrapper -->
      <div class="page_wrapper"><router-view /></div>
      <div v-if="$route.path !== '/'" class="text-center my-3">
        <router-link :to="{ name: 'TopIndex' }">{{ $t('back_to_top') }}</router-link>
      </div>
      <!-- App Footer -->
      <v-footer height="auto" class="pa-3 app--footer">
        <v-row justify="center" no-gutters>
          <span v-if="$route.path === '/policy/'">{{ $t('menu.policy_index_short') }}</span>
          <router-link v-else :to="{ name: 'PolicyIndex' }">{{ $t('menu.policy_index_short') }}</router-link>
          <span class="px-2">|</span>
          <span v-if="$route.path === '/inquiry/'">{{ $t('menu.inquiry') }}</span>
          <router-link v-else :to="{ name: 'InquiryIndex' }">{{ $t('menu.inquiry') }}</router-link>
          <v-col class="text-center" cols="12">
            &copy; かみしほろルーラルOS推進協議会 {{ new Date().getFullYear() }}
          </v-col>
        </v-row>
      </v-footer>
    </v-main>
    <!-- Go to top -->
    <app-fab />
  </div>
</template>

<script>
import AppToolbar from '@/components/AppToolbar'
import AppFab from '@/components/AppFab'

export default {
  name: 'LayoutDefault',
  components: {
    AppToolbar,
    AppFab,
  },
}
</script>
